import React from 'react'; // Importa React, necesario para el uso de JSX y crear componentes.
import './Carrito.css';

const Carrito = ({ cartItems, onCheckout, isLoggedIn }) => {// Define un componente funcional llamado Carrito, con tres props.
  // Calcula el total del carrito sumando el precio de cada producto multiplicado por su cantidad.
  const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
// Función que se ejecuta cuando el usuario hace clic en el botón de compra.
  const handleCheckout = () => {
    onCheckout(); // Llama a la función onCheckout pasada como prop para proceder con el proceso de pago.
  };

  return (
    <div className="carrito-page"> 
        <center><img src="/carrito-menu.png" alt="Banner" className="banner-image" /></center>
        <h2>Carrito de Compras</h2>
      {cartItems.length === 0 ? (  // Si no hay productos en el carrito, muestra un mensaje.
        <p>No hay productos en el carrito.</p>  //Mensaje que va salir cuando todavia no tiene seleccionado en el catalogo
      ) : (   // Si hay productos en el carrito, muestra una tabla con los detalles del carrito.
        
        // Tabla para mostrar los productos en el carrito.
        <table className="cart-table"> 
          <thead>
            <tr>
              <th>Producto</th>
              <th>Precio Unitario</th>
              <th>Cantidad</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item, idx) => ( // Itera sobre los productos en el carrito y los muestra en filas.
              //Debajo muestra los productos, precio por unidad, cantidad seleccionada y el caluclo del subtotal
              <tr key={idx}>
                <td>{item.name}</td>
                <td>${item.price.toFixed(2)}</td>
                <td>{item.quantity}</td>
                <td>${(item.price * item.quantity).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="carrito-total"> 
        <p>Total: ${total.toFixed(2)}</p> 
        <div className="payment-methods">
          <p>Aceptamos:</p> 
          <img src="https://logos-world.net/wp-content/uploads/2020/04/Visa-Logo-1992-2000.png" alt="Visa" style={{width:'50px', marginRight:'10px'}}/>
          <img src="https://th.bing.com/th/id/OIP.pL0yAs7O-twbB-41n4VngAHaEc?rs=1&pid=ImgDetMain" alt="Mastercard" style={{width:'50px', marginRight:'10px'}}/>
          <img src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg" alt="PayPal" style={{width:'50px'}}/>
        </div>
        <button onClick={handleCheckout} disabled={cartItems.length === 0}> 
          Comprar
        </button>
      </div>
    </div>
  ); 
}; 

export default Carrito; // Exporta el componente Carrito para que pueda ser utilizado en otras partes del proyecto.






