// Footer.js
import React from 'react'; // Importa la biblioteca React para usar sus funcionalidades.
import './Footer.css'; // Importa los estilos del archivo CSS para estilizar el componente.

const Footer = () => { // Define un componente funcional de React llamado Footer.
  return (
    <footer className="main-footer">
      <p>© {new Date().getFullYear()} SMART TECH PTY. Todos los derechos reservados.</p> 
      {/* Utiliza JavaScript dentro de las llaves {} para obtener el año actual. 
        `new Date().getFullYear()` devuelve el año actual del sistema. */}
    </footer> // Es la llave que cierra 
  );
};

export default Footer; // Exporta el componente Footer para que pueda ser utilizado en otros archivos.

