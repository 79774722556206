// Header.js
import React from 'react';// Importa la biblioteca React, que es necesaria para crear componentes.
import { Link } from 'react-router-dom';  // Importa el componente Link desde 'react-router-dom' para la navegación dentro de la app.
import './Header.css'; //importa el estilo de CSS

const Header = () => { // Define un componente funcional llamado Header.

  // Aqui abajo se El contenedor principal del encabezado. Tiene una clase CSS aplicada.
   // Un contenedor para el logo.
   // Debajo se crea un contenedor <nav> que agrupa los enlaces de navegación y tambien se agrega el logo con acceso a whatsapp
  return (
    <header className="main-header">  
      <div className="header-logo">  
      <img src="/Logo.png" alt="Logo" className="whatsapp-logo"/> 
        <Link to="/">SMART TECH PTY</Link>
      </div>
      <nav className="header-nav">
        <Link to="/">Inicio</Link>
        <Link to="/catalogo">Catálogo</Link>
        <Link to="/carrito">Carrito</Link>
        <Link to="/contact">Contacto</Link>
        <a href="https://web.whatsapp.com/" target="_blank" rel="noopener noreferrer">
          <img src="/logo_whatsapp.png" alt="WhatsApp" className="whatsapp-logo"/>
        </a>
      </nav>
    </header>
  );
};

export default Header; // Exporta el componente Header para que pueda ser utilizado en otros archivos del proyecto.

