// Catalogo.js
import React from 'react'; // Importa React, necesario para el uso de JSX y la creación de componentes en React.
import { products } from '../data'; // Importa el array de productos desde el archivo '../data', que contiene la lista de productos disponibles.
import ProductCard from '../components/ProductCard';
import './Catalogo.css'; //css

const Catalogo = ({ onAddToCart }) => { // Define el componente funcional Catalogo, que recibe la función onAddToCart como prop.
  return ( 
    // Debajo devuelve el Contenedor principal de la página del catálogo, con la clase CSS 'catalogo-page'.
    // Contenedor que usa una cuadrícula (grid) para mostrar los productos.
    <div className="catalogo-page">
      <center><img src="/catalogo-menu.png" alt="Banner" className="banner-image" /></center> 
      <h2><center>Catálogo de Productos</center></h2>
      <div className="product-grid"> 
        {products.map(prod => ( // Itera sobre el array 'products', que contiene la lista de productos disponibles.
          <ProductCard key={prod.id} product={prod} onAddToCart={onAddToCart} /> // Para cada producto, renderiza un componente ProductCard, pasando el producto y la función onAddToCart
        ))}
      </div>
    </div>
  );
};

export default Catalogo;// Exporta el componente Catalogo para que pueda ser utilizado en otras partes del proyecto.

