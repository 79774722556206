//IniciarSesion
import React, { useState } from 'react'; // Se importa React y el hook useState para manejar el estado del componente.
import './IniciarSesion.css';

const API_URL = 'https://localhost:5000'; // URL base de la API del servidor donde se gestionarán las solicitudes de login y registro.

const IniciarSesion = ({ onLogin }) => {
  // Declaración de los estados utilizados en el formulario.
  const [isRegister, setIsRegister] = useState(false); // Estado para determinar si el usuario está en la vista de registro o inicio de sesión
  const [email, setEmail] = useState(''); // Estado para almacenar el correo electrónico ingresado por el usuario.
  const [password, setPassword] = useState(''); // Estado para almacenar la contraseña ingresada por el usuario.
  const [confirmPass, setConfirmPass] = useState(''); // Estado para almacenar la confirmación de la contraseña (solo en registro)
  const [error, setError] = useState('');// Estado para manejar los errores que puedan ocurrir (como contraseñas no coincidentes o errores del servidor).
  const [loading, setLoading] = useState(false); // Estado para manejar el estado de carga (muestra "Procesando..." mientras se realiza la solicitud)

  // Función encargada de manejar el envío del formulario (inicio de sesión o registro).
  const handleSubmit = async (e) => {
    e.preventDefault(); // Previene la acción por defecto del formulario (recarga de la página).
    setError(''); // Limpia los errores previos.
    setLoading(true); // Establece el estado de carga en true, lo que deshabilita el botón de envío.

 // Validación de que las contraseñas coincidan en caso de registro.
    if (isRegister && password !== confirmPass) {
      setError('Las contraseñas no coinciden');
      setLoading(false);
      return;
    }

    // Establece el endpoint según si es un registro o un inicio de sesión.
    const endpoint = isRegister ? '/api/register' : '/api/login';
    const body = { email, password };  // Cuerpo de la solicitud que contiene el email y la contraseña.

    try {
        // Realiza la solicitud a la API para registrar o iniciar sesión.
      const res = await fetch(`${API_URL}${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });

      const data = await res.json(); // Convierte la respuesta en formato JSON.

      if (!res.ok) {
        setError(data.message || 'Error en la solicitud'); // Si la respuesta no es OK, muestra el error.
      } else {
         // Si es un registro exitoso, muestra un mensaje y cambia la vista a inicio de sesión.
        if (isRegister) {
          alert('Registro exitoso, ahora puedes iniciar sesión');
          setIsRegister(false); // Cambia el estado para mostrar el formulario de inicio de sesión.
        } else {
          // Si es un inicio de sesión exitoso, guarda el token en el localStorage y ejecuta la función onLogin.
          localStorage.setItem('token', data.token);  // Almacena el token en el almacenamiento local.
          onLogin();// Llama a la función onLogin proporcionada como prop.
        }
      }

    } catch (err) {
      console.error(err);
      setError('Error del servidor'); // Muestra un error si ocurre una falla en la solicitud
    }

    setLoading(false); // Finaliza el estado de carga.
  };

  return (
    // Contenedor principal de la página de inicio de sesión o registro.
    // Título que cambia dinámicamente según el estado (registro o inicio de sesión).
    // Muestra un mensaje de error si existe alguno.
    // Formulario que ejecuta handleSubmit al ser enviado.<form onSubmit={handleSubmit} className="login-form">
    // Actualiza el estado del correo electrónico. onChange={e=>setEmail(e.target.value)} 
    // Mas abajo se crea un contenedor que permite cambiar entre las vistas de registro e inicio de sesión.<div className="switch-mode">
    <div className="iniciar-sesion-page">
      <h2>{isRegister ? 'Registrarse' : 'Iniciar Sesión'}</h2>
      {error && <p style={{color: 'red', textAlign: 'center'}}>{error}</p>}
      <form onSubmit={handleSubmit} className="login-form">
        <label>
          Correo Electrónico:
          <input 
            type="email" 
            value={email} 
            onChange={e=>setEmail(e.target.value)} 
            required 
          />
        </label>
        <label>
          Contraseña:
          <input 
            type="password" 
            value={password} 
            onChange={e=>setPassword(e.target.value)} 
            required 
          />
        </label>
        {isRegister && (
          <label>
            Confirmar Contraseña:
            <input 
              type="password" 
              value={confirmPass}
              onChange={e=>setConfirmPass(e.target.value)} 
              required 
            />
          </label>
        )}
        <button type="submit" disabled={loading}>
          {loading ? 'Procesando...' : (isRegister ? 'Registrarse' : 'Iniciar Sesión')}
        </button>
        
      </form>
      <div className="switch-mode">
        {isRegister ? '¿Ya tienes una cuenta?' : '¿Eres nuevo?'} 
        <button onClick={()=>{ setIsRegister(!isRegister); setError(''); }}>
          {isRegister ? 'Iniciar Sesión' : 'Registrarse'}
        </button>
      </div>
    </div>
  );
};

export default IniciarSesion; // Exporta el componente para su uso en otras partes de la aplicación.

