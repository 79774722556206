import React from 'react'; // Importa React para poder usar JSX y crear componentes.
import ReactDOM from 'react-dom/client'; // Importa ReactDOM para manejar la renderización de la aplicación en el DOM
import App from './App'; // Importa el componente principal de la aplicación.

const root = ReactDOM.createRoot(document.getElementById('root')); // Crea un "root" en el DOM donde se montará la aplicación React
root.render(<App />); // Renderiza el componente "App" dentro del elemento con id 'root'.




