// App.js
import React, { useState } from 'react'; // Importa React y useState para manejar el estado local.
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'; // Importa herramientas de enrutamiento de React Router.
import './App.css'; // Importa el archivo CSS para aplicar estilos globales a la aplicación.

import Header from './components/Header'; // Importa el componente de encabezado.
import Footer from './components/Footer'; // Importa el componente de pie de página.

import Inicio from './pages/Inicio'; // Importa la página de inicio.
import Catalogo from './pages/Catalogo'; // Importa la página del catálogo.
import Carrito from './pages/Carrito'; // Importa la página del carrito de compras.
import IniciarSesion from './pages/IniciarSesion'; // Importa la página de inicio de sesión.
import Contact from './pages/Contact'; // Importa la página de contacto.

function App() {
  // Estado para el carrito de compras y la sesión de usuario.
  const [cartItems, setCartItems] = useState([]); // Estado para almacenar los productos en el carrito.
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para saber si el usuario está logueado.

    // Función para agregar productos al carrito.
  const handleAddToCart = (product) => { 
    setCartItems((prevItems) => {
      const existing = prevItems.find(item => item.id === product.id);  // Verifica si el producto ya está en el carrito.
      if (existing) {
        // Si el producto ya está en el carrito, incrementa su cantidad.
        return prevItems.map(item =>
          item.id === product.id
            ? {...item, quantity: item.quantity + 1}  // Incrementa la cantidad del producto
            : item
        );
      } else {
        // Si el producto no está en el carrito, lo agrega con cantidad 1.
        return [...prevItems, {...product, quantity: 1}];
      }
    });
  };

// Función para manejar el proceso de checkout (compra).
  const handleCheckout = (navigate) => {
    if (!isLoggedIn) {
      // Si el usuario no está logueado, redirige a la página de inicio de sesión.
      navigate('/iniciarsesion');
    } else {
      // Si el usuario está logueado, muestra un mensaje de compra exitosa.
      alert("Compra realizada con éxito!");
      setCartItems([]);
    }
  };

    // Función para manejar el inicio de sesión.
  const handleLogin = () => {
    setIsLoggedIn(true); // Cambia el estado de login a true.
  };

  return (
    //Debajo se crea diferentes acciones tales como:
    // Se configura el enrutamiento para la aplicación.
    // Se crea Contenedor principal de la aplicación.
    // Contenedor principal de las rutas y contenido.
     // Define las rutas de la aplicación. <Routes> ya sea para Catalogo, Carrito, Iniciosesion y contacto
    <Router>
      <div className="app-container">
        <Header />
        <main className="app-main">
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/catalogo" element={<Catalogo onAddToCart={handleAddToCart} />} />
            <Route path="/carrito" element={<CarritoWrapper cartItems={cartItems} onCheckout={handleCheckout} isLoggedIn={isLoggedIn} />} />
            <Route path="/iniciarsesion" element={<IniciarSesion onLogin={handleLogin} />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

// Función envolvente para el componente Carrito que incluye la lógica de checkout.
function CarritoWrapper(props) {
  const navigate = useNavigate(); // Hook para obtener la función de navegación
  // Función para manejar el proceso de checkout desde el carrito.
  const onCheckout = () => {
    props.onCheckout(navigate);// Llama a la función de checkout y pasa la función de navegación.
  };
  return <Carrito {...props} onCheckout={onCheckout} />; // Devuelve el componente Carrito con las propiedades necesarias.
}

export default App; // Exporta el componente principal de la aplicación.


