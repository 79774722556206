// Contact.js
import React, { useState } from 'react'; // Importa React y el hook useState para manejar el estado de los campos del formulario.
import './Contact.css';

const Contact = () => { // Declara tres estados para los campos del formulario: nombre, email y mensaje
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [mensaje, setMensaje] = useState('');

 // Función que maneja el envío del formulario.
  const handleSubmit = (e) => {
    e.preventDefault(); // Previene la acción por defecto de enviar el formulario, que recargaría la página
    console.log("Mensaje enviado: ", { nombre, email, mensaje });  // Muestra los datos del formulario en la consola.
    alert("Gracias por contactarnos. Te responderemos pronto."); // Muestra un mensaje de agradecimiento al usuario.
    // Limpia los campos del formulario después del envío.
    setNombre('');
    setEmail('');
    setMensaje('');
  };

  // Contenedor principal de la página de contacto.
  return (
    <div className="contact-page">
      <center><img src="/contacto-menu.png" alt="Banner" className="banner-image" /></center>
      <h2>Contacto</h2>
      <div className="contact-form-container">
        <form onSubmit={handleSubmit}>
          <label>
            Nombre:
            <input type="text" value={nombre} onChange={e=>setNombre(e.target.value)} required />
          </label>
          <label>
            Correo Electrónico:
            <input type="email" value={email} onChange={e=>setEmail(e.target.value)} required />
          </label>
          <label>
            Mensaje:
            <textarea value={mensaje} onChange={e=>setMensaje(e.target.value)} required></textarea>
          </label>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default Contact; // Exporta el componente Contact para su uso en otras partes del proyecto.

