// Inicio.js
import React from 'react'; // Importa React para poder usar JSX y componentes funcionales.
import './Inicio.css'; // Se importa el CSS

// Se crea el Contenedor principal de la página de inicio.
// Y se crean el banner dentro d el pagina, y parrafos sobre la empresa de nosotros
const Inicio = () => {
  return (
    <div className="inicio-page">
      <div className="banner-container">
        <img src="/banner.jpg" alt="Banner" className="banner-image" />
      </div>
      <section className="sobre-nosotros">
        <h2><center>Sobre Nosotros</center></h2>
        <p>Somos una empresa comprometida con la calidad y la atención al cliente. Ofrecemos los mejores productos con el mejor servicio, asegurando siempre la satisfacción de nuestros clientes.</p>
        <p>En SMART TECH, nuestra pasión por la tecnología nos impulsa a ofrecerte lo mejor en gadgets, dispositivos electrónicos y todo tipo de soluciones tecnológicas para facilitar tu día a día. Nos dedicamos a brindarte productos innovadores que se adaptan a tus necesidades, con un enfoque en calidad, rendimiento y valor.</p>
        <p><b><center>Quienes Somos</center></b></p>
              <p>SMART TECH nació con el objetivo de ser más que solo una tienda de tecnología. Desde nuestro inicio, hemos querido ser el lugar donde los entusiastas de la tecnología puedan encontrar productos de vanguardia, asesoría experta y un servicio personalizado. Nos enorgullece contar con un equipo altamente capacitado y apasionado por lo que hace. Nuestro conocimiento sobre los últimos avances en tecnología nos permite ofrecerte productos de las mejores marcas, siempre con la seguridad de que estarás tomando la mejor decisión.</p>
              <p><b><center>¿Por que elegirnos?</center></b></p> 
              <p><b>Productos de Alta Calidad:</b> Solo trabajamos con marcas que comparten nuestra visión de excelencia. Desde smartphones hasta computadoras, cada artículo que ofrecemos ha sido seleccionado cuidadosamente para asegurar que nuestros clientes reciban lo mejor.</p> 
              <p><b>Asesoría Experta:</b> Sabemos que elegir el producto adecuado puede ser desafiante. Nuestro equipo está listo para ayudarte a tomar decisiones informadas y encontrar el equipo perfecto según tus necesidades y presupuesto.</p>
              <p><b>Precios Justos y Transparentes:</b> Creemos que la calidad no tiene que ser sinónimo de precios elevados. Nos esforzamos por ofrecerte los mejores precios, siempre con la claridad y transparencia que nos caracteriza.</p> 
              <p><b>Envíos Rápidos y Seguros:</b> En SMART TECH, sabemos lo emocionante que es recibir un producto nuevo. Por eso, garantizamos entregas rápidas y seguras, con opciones de seguimiento para que siempre sepas el estado de tu pedido.</p> 
              <p><b>Atención al Cliente Cercana:</b> Estamos comprometidos con brindarte una experiencia de compra excepcional. Si tienes dudas, inquietudes o necesitas soporte, nuestro equipo de atención al cliente estará disponible para ofrecerte la mejor asistencia.</p>
            
      </section>
    </div>
  );
};

export default Inicio;
