// data.js
// Aqui se crea todos los productos 
export const products = [
    {
      id: 1,
      name: "Laptop ASUS 10 PULG",
      price: 350.00,
      image: "/product1.jpg",
    },
    {
      id: 2,
      name: "Laptop MAC PRO 13 Pulg",
      price: 1200.00,
      image: "/product2.jpg",
    },
    {
      id: 3,
      name: "Teclado Color Negro",
      price: 15.00,
      image: "/product3.jpg",
    },
  
    {
      id: 4,
      name: "Computadora AOC 14 PULG",
      price: 150.00,
      image: "/product4.jpg",
    },
    {
      id: 5,
      name: "Computadora COMPAG",
      price: 150.00,
      image: "/product5.jpg",
    },
    {
      id: 6,
      name: "CPU para Gamer",
      price: 190.00,
      image: "/product6.jpg",
    },
    {
      id: 7,
      name: "Laptop DELL 14 Pulg",
      price: 450.00,
      image: "/product7.jpg",
    },

    {
      id: 8,
      name: "SMARTPHONE ANDROID XIAOMI",
      price: 250.00,
      image: "/product8.jpg",
    },
    {
      id: 9,
      name: "iPHONE 16 PRO MAX",
      price: 1200.00,
      image: "/product9.jpg",
    },
    {
      id: 10,
      name: "SMARTPHONE GALAXY",
      price: 500.00,
      image: "/product10.jpg",
    },
    {
      id: 11,
      name: "SMARTPHONE SAMSUNG A14",
      price: 150.00,
      image: "/product11.jpg",
    },
    {
      id: 12,
      name: "SMARTPHONE HUAWEI",
      price: 450.00,
      image: "/product12.jpg",
    },
    {
      id: 13,
      name: "SMARTPHONE HONOR X8a",
      price: 250.00,
      image: "/product13.jpg",
    },
    {
      id: 14,
      name: "SMARTPHONE GALAXY PRO",
      price: 400.00,
      image: "/product14.jpg",
    },
    {
      id: 15,
      name: "SMARTWATCH PRO",
      price: 122.00,
      image: "/product15.jpg",
    },
    {
      id: 16,
      name: "AUDIFONO BLUE",
      price: 70.00,
      image: "/product16.jpg",
    },
    {
      id: 17,
      name: "AUDIFONO AIRPOP HORA",
      price: 15.50 ,
      image: "/product17.jpg",
    },
    {
      id: 18,
      name: "MOUSE/RATON COLORES",
      price: 15.00,
      image: "/product18.jpg",
    },
    
    {
      id: 19,
      name: "CARGADOR CON CABLE TIPO C HORA",
      price: 25.00,
      image: "/product19.jpg",
    },
    {
      id: 20,
      name: "PROTECTOR DEL TELEFONO CELULAR/ COLOR ROJO",
      price: 12.00,
      image: "/product20.jpg",
    },
    {
      id: 21,
      name: "REGULADOR DE VOLTAJE",
      price: 50.00,
      image: "/product21.jpg",
    },

  ];
  