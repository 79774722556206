// ProductCard.js
import React from 'react'; // Importa la librería React para utilizar JSX y crear componentes.
import './ProductCard.css'; // Importa el archivo CSS con los estilos para el componente.

const ProductCard = ({ product, onAddToCart }) => { // Define un componente funcional llamado ProductCard.
  return ( // Retorna el JSX que representa la estructura visual de la tarjeta del producto.

    // Acontinuación de crea Un contenedor principal con la clase "product-card" para estilizar la tarjeta, y  
    // Un contenedor principal con la clase "product-card" para estilizar la tarjeta.
    // Imagen del producto, con src y alt dinámicos según las propiedades del objeto product.
    // Muestra el nombre del producto. <h3>{product.name}</h3>
    // Muestra el precio del producto con dos decimales. <p>Precio: ${product.price.toFixed(2)}</p>
    // Botón que agrega el producto al carrito al hacer clic. <button onClick={() => onAddToCart(product)}>Agregar al carrito</button>
    <div className="product-card"> 
      <div className="card-img-container">
        <img src={product.image} alt={product.name} />
      </div>
      <h3>{product.name}</h3>
      <p>Precio: ${product.price.toFixed(2)}</p>
      <button onClick={() => onAddToCart(product)}>Agregar al carrito</button>
    </div>
  );
};

export default ProductCard; // Exporta el componente para que pueda ser utilizado en otros archivos.
